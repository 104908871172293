<template>
  <div>
    <Modal
      v-model="showPreviewModal"
      title="Preview da Prova"
      :enableClose="true"
    >
      <ProvaPreview :prova="provaPreview" :editing="true"> </ProvaPreview>
    </Modal>
    <Modal
      v-model="showModalEscolhaOrigemQuestao"
      title="Cria nova Questão"
      :enableClose="true"
    >
      <div
        class="row"
        style="padding-top: 40px; padding-bottom: 40px; height: 200px"
      >
        <div class="col-md-6">
          <button
            :class="{ activeMenu: page == 'adicionarQuestao' }"
            class="btn-block btn btn-light"
            style="
              height: 100%;
              border: 1px solid #ddd;
              color: #333333 !important;
            "
            @click.prevent="
              page = 'adicionarQuestaoBanco';
              showModalEscolhaOrigemQuestao = false;
            "
          >
            Banco de questões
          </button>
        </div>
        <div class="col-md-6">
          <button
            class="btn-block btn"
            style="height: 100%; border: 1px solid #ddd; color: #333333"
            @click.prevent="
              cadastrarNovaQuestao();
              showModalEscolhaOrigemQuestao = false;
            "
          >
            <div>
              <img src="../../../assets/images/criar-questao.png" width="40%" />
              <br />
              Criar Questão
            </div>
          </button>
        </div>
      </div>
    </Modal>

    <Modal
      v-model="showModalAlunos"
      title="Selecionar Alunos"
      :width="800"
      :adaptive="true"
      :enableClose="true"
    >
      <div class="row">
        <div class="col-md-12">
          <form class="block__form">
            <div class="form-row">
              <div class="col-auto" style="width: 100%">
                <input
                  autocomplete="off"
                  id="search"
                  v-model="filterAlunos"
                  type="search"
                  class="form-control"
                  placeholder="Busque por um nome ou e-mail de aluno"
                  style="padding-left: 40px"
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <hr class="full-hr" />

      <div class="row">
        <div class="col-sm-12">
          <ul class="list-inline m-0">
            <li class="list-inline-item">
              <button
                style="width: 150px"
                class="btn btn-classic"
                @click.prevent="showModalAlunos = false"
              >
                Salvar
              </button>
            </li>

            <li class="list-inline-item">
              <button
                style="width: 150px"
                class="btn btn-outline"
                @click.prevent="showModalAlunos = false"
              >
                Cancelar
              </button>
            </li>
          </ul>
          <br />
          <table
            class="
              block__classic
              table table__classic table-striped table-borderless table-hover
              text-center
              mb-4
            "
          >
            <thead>
              <tr>
                <th width="10%">
                  <input
                    autocomplete="off"
                    type="checkbox"
                    class="form-control"
                    :value="all"
                    style="width: 20px; height: 20px"
                    v-if="!checkAllAlunos"
                    @change="checkAll($event)"
                  />
                  <input
                    autocomplete="off"
                    type="checkbox"
                    class="form-control"
                    :value="all"
                    style="width: 20px; height: 20px"
                    v-if="checkAllAlunos"
                    :checked="true"
                    @change="checkAll($event)"
                  />
                </th>
                <th scope="col" width="60%" style="text-align: left">Nome</th>
                <th scope="col" width="40%" style="text-align: left">E-mail</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in alunos" :key="item.id">
                <td>
                  <input
                    type="checkbox"
                    class="form-control"
                    :value="alunos[index]"
                    style="width: 20px; height: 20px"
                    @change="lookAlunos(alunos[index])"
                    v-if="!checkAllAlunos"
                  />
                  <input
                    type="checkbox"
                    class="form-control"
                    :value="alunos[index]"
                    :checked="true"
                    v-if="checkAllAlunos"
                    style="width: 20px; height: 20px"
                  />
                </td>
                <td
                  style="text-align: left"
                  v-html="highlightMatches(item.nome)"
                />
                <td style="text-align: left" v-html="item.usuario.email" />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Modal>

    <main
      id="content"
      class="main-content page-questions pt-0 bg-oct"
      role="main"
    >
      <div
        class="
          page-questions__header
          d-flex
          justify-content-between
          align-items-center
          div-fixa-menu2
          nav-secondary
        "
        style="color: #fff; height: 70px"
        v-bind:class="{ 'div-fixa-menu2': page == 'adicionarQuestao' }"
      >
        <a
          href=""
          v-if="page == 'adicionarQuestao'"
          :class="{ activeMenu: page == 'provas' }"
          @click.prevent="backProvas()"
          class="link fs-24"
          style="color: #fff"
        >
          <i class="fas fa-chevron-left mr-3" />
          Provas
        </a>

        <router-link
          v-if="this.$route.name == 'questao_create'"
          to="/provas"
          class="link fs-24"
          style="color: #fff"
        >
          <i class="fas fa-chevron-left mr-3" />
          Banco de Questões
        </router-link>

        <router-link
          v-if="this.$route.name == 'provas_edit' && page != 'adicionarQuestao'"
          to="/provas"
          class="link fs-24"
          style="color: #fff"
        >
          <i class="fas fa-chevron-left mr-3" />
          Provas
        </router-link>

        <span>{{ prova.titulo }}</span>

        <ul class="list-inline m-0">
          <li class="list-inline-item" style="padding-right: 10px">
            <a
              href=""
              class="link"
              style="color: #fff; font-size: 18px"
              @click.prevent="preview()"
            >
              <i class="far fa-eye" /> Preview
            </a>
          </li>
          <!--<li class="list-inline-item" style="padding-right: 10px">
            <a
              href=""
              class="link"
              style="color: #fff; font-size: 18px"
              @click.prevent="fluxoStatus('EmRevisao')"
            >
              <i style="color: #fff;"
                ><img :src="require('../../../assets/images/acoes/upload.svg')"
              /></i>
              Enviar para revisão
            </a>
          </li>-->
          <li class="list-inline-item" style="padding-right: 10px">
            <div
              style="
                background-color: white;
                height: 30px;
                color: #10a558;
                border-radius: 5px;
              "
            >
              <a
                href=""
                class="color-proj-primary"
                style="
                  font-size: 18px;
                  padding: 10px;
                  margin-top: 11px;
                "
                @click.prevent="salvar"
              >
                Salvar prova
              </a>
            </div>
          </li>
          <li class="list-inline-item" v-if="prova.status == 'Disponibilizado'">
            <a
              href=""
              class="link"
              style="color: #fff"
              @click.prevent="enviarRevisao"
            >
              <i class="fas fa-share" /> Enviar para Revisão
            </a>
          </li>
        </ul>
      </div>

      <div
        class="
          page-questions__header
          d-flex
          justify-content-between
          align-items-center
          div-fixa-menu
        "
        v-bind:class="{ 'div-fixa-menu': page == 'adicionarQuestao' }"
      >
        <div class="row" style="margin: auto">
          <div class="col-md-12">
            <a
              href=""
              style="
                padding: 0 20px;
                border-right: 1px solid #666;
                color: white;
              "
              :class="{ activeMenu: page == 'configuracoes' }"
              @click.prevent="page = 'configuracoes'"
              ><i class="fas fa-cog" /> Configurações
            </a>
            <a
              href=""
              style="
                padding: 0 20px;
                border-right: 1px solid #666;
                color: white;
              "
              :class="{ activeMenu: page == 'provas' }"
              @click.prevent="page = 'provas'"
              ><i><img src="../../../assets/images/peso.svg" /> </i> Prova em
              edição

              <span class="badge" v-if="prova.ordem != 'Aleatória'">
                {{ pesoQuestaoProva }}</span
              >

              <span class="badge bd-2" v-if="prova.ordem == 'Aleatória'"
                >{{ prova.questoes.length }}/{{ prova.qtdQuestoes }}</span
              >
            </a>
            <!--<a
              style="padding: 0 20px; border-right: 1px solid #666"
              :class="{ activeMenu: page == 'provas' }"
              v-if="prova.ordemQuestoes == 'Sequencial'"
              ><i class="fas fa-server" /> Peso da Prova
              <span class="badge">{{ pesoQuestaoProva }}</span></a
            >
            <a
              style="padding: 0 20px; border-right: 1px solid #666"
              :class="{ activeMenu: page == 'provas' }"
              v-if="prova.ordemQuestoes == 'Aleatória'"
              ><i class="fas fa-server" /> {{ prova.questoes.length }}/{{
                prova.numeroQuestoes
              }}</a
            >-->
            <a
              href=""
              style="padding: 0 20px; color: white"
              @click.prevent="abrirModalEscolhaOrigemQuestao()"
              ><i class="fas fa-plus" /> Adicionar Questão</a
            >
          </div>
        </div>
      </div>
      <br /><br /><br />
      <br /><br /><br />

      <!-- Configurações -->
      <div v-if="page == 'configuracoes'" style="padding: 40px">
        <h4 style="color: #d2d2d2"><i class="fas fa-cog" /> Configurações</h4>

        <div style="padding-top: 30px" class="row">
          <div class="col-lg-5 col-md-8">
            <div>
              <label for="disciplina"
                >Componente Curricular
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>

              <!-- <select2
                id="disciplina"
                v-model="disciplinaSelecionada"
                :settings="{ multiple: false, placeholder: 'Selecione' }"
                :options="disciplinas"
              /> -->
              <select
                id="disciplina"
                v-model="disciplinaSelecionada"
                class="form-control"
              >
                <option
                  v-for="item in disciplinas"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.text }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-2 col-md-4">
            <div class="form-group">
              <label for="ano"
                >Ano <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <select
                id="ano"
                v-model="prova.ano"
                class="form-control"
                :disabled="anos.length <= 0"
                @change="onChangeAno()"
              >
                <option
                  v-for="ano in anosOptions"
                  :key="ano.id"
                  :value="ano.id"
                >
                  {{ ano.titulo }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-2 col-md-8">
            <div class="form-group">
              <label for="turma"
                >Turma <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <select
                id="turma"
                v-model="turmaSelecionada"
                class="form-control"
                :disabled="turmas.length == 0"
                @change="onChangeTurma()"
              >
                <option
                  v-for="turma in turmas"
                  :key="turma.id"
                  :value="turma.id"
                >
                  {{ turma.titulo }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-4">
            <div class="form-group" style="padding-top: 30px">
              <div class="alunos">
                <h4 style="font-size: 14px; text-align: center; padding-top: 13px">
                  <b>{{ prova.alunos.length | strpad }}</b> alunos selecionados
                </h4>
              </div>
              <div style="font-size: 12px; text-align: right">
                <a href="" class="link" @click.prevent="listarAlunos()">
                  <img src="../../../assets/images/acoes/editar.svg" />Editar Alunos
                </a>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div style="padding-top: 30px" class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="titulo"
                >Título da Prova
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <input
                id="titulo"
                v-model="prova.titulo"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <!-- <div class="col-md-2">
            <div class="form-group">
              <label for="peso">Peso da Prova <i
                class="far fa-question-circle"
                style="color: #D2D2D2;"
              /></label>
              <input
                id="peso"
                v-model="prova.peso"
                type="number"
                class="form-control"
              >
            </div>
          </div> -->

          <!-- <div class="col-md-3">
            <div class="form-group">
              <label for="ordem"
                >Ordem da prova
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <select
                id="ordem"
                v-model="prova.ordemQuestoes"
                class="form-control"
              >
                <option
                  v-for="ordem in ordensProva"
                  :key="ordem"
                  :value="ordem"
                >
                  {{ ordem }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-3" v-if="prova.ordemQuestoes == 'Aleatória'">
            <div class="form-group">
              <label for="peso"
                >Peso da Prova
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <input
                id="peso"
                v-model="prova.peso"
                type="number"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-3" v-if="prova.ordemQuestoes == 'Aleatória'">
            <div class="form-group">
              <label for="numQuestoes"
                >Número de questões
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <input
                id="numQuestoes"
                v-model="prova.numeroQuestoes"
                type="number"
                class="form-control"
              />
            </div>
          </div> -->

          <div class="col-md-3">
            <div class="form-group">
              <label for="tempoExecucao"
                >Tempo de execução
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <input
                id="tempoExecucao"
                v-model="prova.duracao"
                type="number"
                min="1"
                class="form-control"
                style="width: 160px"
              />
              <span style="font-size: 12px; font-style: italic; color: #333333"
                >Em minutos</span
              >
            </div>
          </div>

        </div>
        <div class="row" style="padding-top: 30px;">

          <div class="col-lg-4 col-md-5">
            <div class="form-group" style="padding-top: 30px">
              <label
                >Data Hora
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <vc-date-picker
                v-model="prova.dataHoraInicio"
                :color="corEmpresa"
                :value="null"
                mode="dateTime"
                is-expanded
                :is24hr="true"
              />
            </div>
          </div>

          <div class="col-lg-4 col-md-3">
            <div class="form-group" style="padding-top: 30px">
              <label for="descricao"
                >Descrição da Prova
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <textarea
                id="descricao"
                v-model="prova.descricao"
                rows="13"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group" style="padding-top: 30px">
              <label for="instrucoes"
                >Instruções da Prova
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <textarea
                id="instrucoes"
                v-model="prova.instrucoes"
                rows="13"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Prova -->
      <div v-if="page == 'provas'" style="padding: 40px">
        <h4 style="color: #d2d2d2"><i class="fas fa-list" /> Prova</h4>

        <p v-if="prova.questoes.length <= 0" style="text-align: center">
          <a
            href=""
            class="btn btn-classic"
            @click.prevent="abrirModalEscolhaOrigemQuestao()"
            ><i class="fas fa-plus" /> Adicionar Questão</a
          >
        </p>

        <div>
          <questao-list
            v-for="questao in prova.questoes"
            :key="questao.id"
            :questao-prop="questao"
            :ordemProva="prova.ordemQuestoes"
            :is-list-prova="false"
            :is-list-inserida-prova="true"
            :minhaQuestao="true"
          />
        </div>
      </div>

      <!-- Adicionar Questão do banco -->
      <div v-if="page == 'adicionarQuestaoBanco'">
        <br />
        <div class="page-questions__content">
          <div class="content-left">
            <form class="block__form has-tooltip">
              <div class="form-group">
                <label
                  >Origem
                  <i
                    class="help ml-1"
                    data-toggle="tooltip"
                    title="Some tooltip text!"
                /></label>

                <div
                  class="custom-control custom-radio custom-control-inline pl-3"
                >
                  <input
                    id="questoes-banco"
                    type="radio"
                    v-model="filtro.origem"
                    value="todas"
                    name="origem"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label small" for="questoes-banco"
                    >Questões do Banco</label
                  >
                </div>

                <div
                  class="custom-control custom-radio custom-control-inline pl-3"
                >
                  <input
                    id="favoritas"
                    type="radio"
                    name="origem"
                    value="favoritas"
                    v-model="filtro.origem"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label small" for="favoritas"
                    >Favoritas</label
                  >
                </div>

                <div
                  class="custom-control custom-radio custom-control-inline pl-3"
                >
                  <input
                    id="criadas"
                    type="radio"
                    name="origem"
                    value="minhas"
                    v-model="filtro.origem"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label small" for="criadas"
                    >Criadas por mim</label
                  >
                </div>
              </div>

              <div class="form-group full-select">
                <label for="habilidade"
                  >Habilidade
                  <i
                    class="help ml-1"
                    data-toggle="tooltip"
                    title="Some tooltip text!"
                /></label>
                <select
                  id="ano"
                  v-model="filtro.assuntosSelecionados"
                  class="form-control"
                >
                  <option
                    v-for="data in assuntosDisponiveis"
                    :key="data.id"
                    :value="data.id"
                  >
                    {{ data.text }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label
                  >Tipo
                  <i
                    class="help ml-1"
                    data-toggle="tooltip"
                    title="Some tooltip text!"
                /></label>
                <div
                  class="custom-control custom-radio custom-control-inline pl-3"
                >
                  <input
                    id="objetiva"
                    type="radio"
                    v-model="filtro.tipo"
                    value="Objetiva"
                    name="tipo"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label small" for="objetiva"
                    >Objetiva</label
                  >
                </div>
                <div
                  class="custom-control custom-radio custom-control-inline pl-3"
                >
                  <input
                    id="descritiva"
                    type="radio"
                    v-model="filtro.tipo"
                    value="Descritiva"
                    name="tipo"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label small" for="descritiva"
                    >Descritiva</label
                  >
                </div>
                <div
                  class="custom-control custom-radio custom-control-inline pl-3"
                >
                  <input
                    id="todas"
                    type="radio"
                    v-model="filtro.tipo"
                    value="todas"
                    name="tipo"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label small" for="todas"
                    >Todas</label
                  >
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-md-12">
                    <button
                      class="btn btn-classic btn-block"
                      @click.prevent="filtrar"
                    >
                      <i class="fas fa-search" /> Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="content-right px-4">
            <questao-list
              v-for="questao in questoesDisponiveis"
              :key="questao.id"
              :questao-prop="questao"
              :is-list-prova="true"
              :is-list-inserida-prova="false"
              :emailCriador="questao.criadaPor.email"
            />
          </div>
        </div>
      </div>
      <!-- Adicionar Questão -->
      <div v-if="page == 'adicionarQuestao'" style="padding-top: -10px">
        <div class="page-questions__content">
          <div class="content-left">
            <QuestaoFilter
              :filtro="filtro"
              :disciplinasDisponiveis="disciplinasDisponiveis"
              :assuntosDisponiveis="assuntosDisponiveis"
              :anosDisponiveis="anosDisponiveis"
              :disciplinaSlc="prova.disciplina"
            />
          </div>
          <div class="content-right px-4">
            <p class="mb-4"></p>
            <questao-list
              v-for="questao in questoesDisponiveis"
              :key="questao.id"
              :questao-prop="questao"
              :is-list-prova="true"
              :is-list-inserida-prova="false"
            />
          </div>
        </div>
      </div>

      <!--Preview -->
      <!-- Prova -->
      <div style="padding: 40px" v-if="page == 'preview'">
        <prova-preview :prova="this.prova" />
      </div>
      <!--Fim -->

      <div v-if="page == 'criandoNovaQuestao'">
        <questao-create
          :is-cadastra-dentro-prova="true"
          :disciplinaProva="disciplinaSelecionada"
          ref="questaoCreateEdit"
        />
      </div>
    </main>
  </div>
</template>

<script>
import Vue from "vue";
import Swal from "sweetalert2";
import VCalendar from "v-calendar";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import Select2 from "v-select2-component";
import QuestaoList from "@/components/questoes/QuestaoList";
import QuestaoCreate from "@/modules/questoes/pages/QuestaoCreate";
import store from "@/store";
import ProvaPreview from "@/modules/provas/pages/ProvaPreview";
import QuestaoFilter from "@/components/questoes/QuestaoFilter";
import { constantes } from "@/constants";

Vue.use(VCalendar, {
  componentPrefix: "vc",
  QuestaoCreate,
  Select2,
});

export default {
  components: {
    QuestaoCreate,
    Modal: VueModal,
    // Select2,
    QuestaoList,
    ProvaPreview,
    QuestaoFilter,
  },
  data() {
    return {
      all: false,
      showPreviewModal: false,
      provaPreview: Object,
      assuntosDisponiveis: [],
      disciplinasDisponiveis: [],
      filtro: {
        page: 1,
        size: 1,
        disciplinaSelecionada: null,
        assuntosSelecionados: null,
        anoSelecionado: null,
        tipo: "",
        search: "",
        origem: "",
      },
      questoesEdicoes: [],
      pesoQuestaoProva: 0,
      ordensProva: ["Sequencial"],
      showModalEscolhaOrigemQuestao: false,
      questoesDisponiveis: [],
      page: "configuracoes",
      hoje: new Date(),
      assuntos: [],
      assuntoSelecionado: "",
      tipoQuestao: "",
      nivelQuestao: "",
      alunos: [],
      anos: [],
      turmas: [],
      turmaSelecionada: null,
      disciplinas: [],
      disciplinaSelecionada: null,
      showModalAlunos: false,
      checkAllAlunos: false,
      questoesFiltrar: [],
      filterAlunos: "",
      showLeftMenu: true,
      hasFilter: this.$route.params.filter ? this.$route.params.filter : false,
      questaoProva: {
        idQuestao: "",
        valor: 0,
      },

      prova: {
        disciplina: "",
        alunos: [],
        questoes: [],
        ano: "",
        turma: "",
        titulo: "",
        peso: "",
        descricao: "",
        instrucoes: "",
        dataHoraInicio: "",
        dataHora: "",
        duracao: "",
        ordemQuestoes: "Sequencial",
        ordem: "Sequencial"
      },
    };
  },
  computed: {
    filteredAlunosRows() {
      return this.alunos.filter((row) => {
        const searchTerm = this.filterAlunos.toLowerCase();
        const text = row.nome.toLowerCase();
        return text.includes(searchTerm);
      });
    },
    anosOptions() {
      this.anos.map(function (entry) {
        entry.text = entry.titulo;
      });
      return this.anos;
    },
    corEmpresa() {
      debugger;
      if(constantes.EMPRESA === 'veloz'){
        return 'blue';
      }else if(constantes.EMPRESA === 'uniavan'){
        return 'green';
      }
      return '';
    },
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadDisciplinas();

    this.loadAnos();
    this.loadProva();
  },
  methods: {
    somaPeso(val, questaoEditada) {
      val = parseFloat(val);
      if (!val) val = 0;

      if (this.questoesEdicoes.includes(questaoEditada)) {
        let i = this.questoesEdicoes.findIndex(
          (x) => x.id == questaoEditada.id
        );
        this.questoesEdicoes[i].peso = parseFloat(val);
      } else {
        questaoEditada.peso = parseFloat(val);
        this.questoesEdicoes.push(questaoEditada);
      }

      let valueSum = 0;
      this.questoesEdicoes.forEach((q) => {
        valueSum += q.peso;
      });
      this.pesoQuestaoProva = valueSum;
      this.prova.peso = valueSum;
    },
    depoisCriarQuestaoNaProva(prova) {
      this.insereQuestao(prova);
      this.page = 'criandoNovaQuestao';
      this.$refs.questaoCreateEdit.resetWindow();
    },
    abrirModalEscolhaOrigemQuestao() {
      this.showModalEscolhaOrigemQuestao = true;
    },
    cadastrarNovaQuestao() {
      this.page = "criandoNovaQuestao";
    },
    voltaParaListaQuestoes() {
      this.loadQuestoes();
      this.page = "adicionarQuestao";
    },
    filtrarQuestoes() {},
    deletaQuestao(questao) {

      this.prova.questoes = this.prova.questoes.filter(function (value) {
        if (value.id == questao.id) {
          return false;
        }
        return true;
      });
      this.$forceUpdate();
    },
    insereQuestao(questao) {
      var findQuestao = this.prova.questoes.find(
        (element) => element.id == questao.id
      );

      if (findQuestao) {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Essa questão já foi inserida nesta prova!</span>',
          showConfirmButton: false,
          timer: 2500,
        });

        return;
      } else {
        if (this.prova.ordem == "Aleatória") {
          // if (this.prova.questoes.length <= this.prova.qtdQuestoes) {
          // var questaoComPeso = {
          //   questao: questao,
          //   obrigatoria: false,
          // };

          this.prova.questoes.unshift(questao);

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Questão adicionada com sucesso na prova!",
            showConfirmButton: false,
            timer: 1500,
          });

          if (
            this.$route.name == "questao-create" ||
            this.$route.name == "questao-edit"
          ) {
            this.page = "questoes";
          }
        } else {
          questao.peso = 1;
          var questaoComPeso2 = {
            questao: questao,
            obrigatoria: false,
          };

          this.somaPeso(parseFloat(questao.peso), questao);
          this.prova.questoes.unshift(questao);

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Questão adicionada com sucesso na prova!",
            showConfirmButton: false,
            timer: 1500,
          });

          if (
            this.$route.name == "questao-create" ||
            this.$route.name == "questao-edit"
          ) {
            this.page = "questoes";
          }
        }
      }
      console.log(this.prova.peso);
    },
    pad(value) {
      return value.toString().padStart(2, 0);
    },
    backProvas() {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Você deseja sair?",
        text: "Ao sair você perde todo seu progresso",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Sair",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      }).then((res) => {
        if (res.isConfirmed) {
          this.irTelaProvas()
        }
      });
    },

    irTelaProvas(){
      this.$router.push({
        name: "provas",
      });
    },

    validateSalvar(){

      if (!this.prova.disciplina || this.prova.disciplina == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou o componente curricular para a prova.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      } 
      if (!this.prova.ano || this.prova.ano == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou o ano para a prova.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (!this.prova.turma || this.prova.turma == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou a turma para a prova.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (!this.prova.titulo || this.prova.titulo == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou o título para a prova.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (!this.prova.duracao || this.prova.duracao == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou o tempo de execução para a prova.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (!this.prova.descricao || this.prova.descricao == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou a descrição para a prova.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (!this.prova.instrucoes || this.prova.instrucoes == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou as instruções para a prova.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (this.prova.dataHoraInicio.valueOf() <= new Date().valueOf()) {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! A data da prova não pode ser inferior a data atual.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      let possuiPesoInvalido = false;
      let todosPesosPreenchidos = true;
      this.prova.questoes.forEach((questao, index) => {
        if(!questao.peso){
          todosPesosPreenchidos = false;
        }else if(questao.peso < 0){
          Swal.fire({
            position: "center",
            icon: "error",
            title:
              '<span style="font-size: 20px;">Oops! Algumas questões estão com valores negativos em seu peso, por favor informe o peso correto em valores positivos!</span>',
            showConfirmButton: false,
            timer: 6000,
          });
          possuiPesoInvalido = true;
        }
      })
      if(possuiPesoInvalido){
        return false;
      }

      if(!todosPesosPreenchidos && this.prova.ordem == "Sequencial"){
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! As questões da prova devem ter seu peso preenchido.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (this.prova.ordem == "Aleatória" && !this.prova.peso) {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! O campo Peso da Prova é obrigatório para provas de ordem aleatória.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      return true;
    },

// Convertendo dados para enviar ao back-end sem influenciar no model do front-end
    converterDados(prova){
      let data = Object.assign({}, prova);
      const questoes = [];
      data.questoes.forEach(questao => {
        if(questao.questao){
          questoes.push(questao)
        }else{
          questoes.push({
            questao: questao,
            peso: questao.peso || 1
          })
        }
      });
      data.questoes = questoes;
      return data
    },

    salvar() {
      this.prova.disciplina = this.disciplinaSelecionada;
      this.prova.turma = this.turmaSelecionada;

      if(!this.validateSalvar()){
        return false;
      }

      this.$root.$emit("Spinner::show");

      //@todo refatorar sanitizacao do objeto prova para salvar no banco
      this.sincronizarDados();

      this.$api
        .put("provas-edit/" + this.$route.params.id, this.converterDados(this.prova))
          .then(() => {
            this.$root.$emit("Spinner::hide");

            this.loadProva();

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Prova atualizada com sucesso!",
              showConfirmButton: false,
              timer: 1500,
            }).then(this.irTelaProvas);
          })
          .catch((error) => {
            this.$root.$emit("Spinner::hide");
            if (error.response.status == 400) {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.detail +
                  "</span>",
                showConfirmButton: false,
                timer: 3500,
              }).then(() => {
                this.loadData();
                this.showModalCreate = false;
              });
            } else if (error.response.status == 401) {
              store.dispatch("auth/ActionSignOut");
              window._Vue.$router.push({ name: "login" });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.violations[0].message +
                  "</span>",
                showConfirmButton: false,
                timer: 2500,
              });
            }
          });
    },
    loadQuestoes() {
      this.$root.$emit("Spinner::show");
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.filtro.disciplinaSelecionada = this.prova.disciplina;
      this.loadAssuntos(this.prova.disciplina.id);
      this.filtro.size = this.itensPerPage;
      this.page = this.filtro.page;
      console.log(this.filtro)
      this.$api
        .post("/questoes-paginacao-trash", this.filtro)
        .then((response) => {
          console.log(response)
          this.total = response.data.totalItens;
          this.totalPaginas = response.data.paginas;
                    
          if (idInstituicao == 0) {
            this.questoes = response.data.data;
          } else {
            response.data.data.forEach((res) => {
              if (res.trashAt === null) {
                this.questoes.push(res);
              }
            });
          }
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          this.$root.$emit("Spinner::hide");

          if (error.response.status == 403) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">Oops! Você não tem permissão para executar esta ação.</span>',
              showConfirmButton: false,
              timer: 2500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.violations[0].message +
                "</span>",
              showConfirmButton: false,
              timer: 2500,
            });
          }
          this.$root.$emit("Spinner::hide");
        });
    },
    filtrar() {
      this.questoes = [];
      this.questoesDisponiveis = [];

      console.log(this.filtro)
      if(this.filtro.disciplinaSelecionada == undefined){
        this.filtro.disciplinaSelecionada = null;
      }

      if(this.filtro.tipo == "todas"){
        this.filtro.tipo = "";
      }

      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.$root.$emit("Spinner::show");
      this.filtro.size = "10";
      this.$api
        .post("/questoes-paginacao", this.filtro)
        .then((response) => {
            response.data.data.forEach((res) => {
              if (idInstituicao == 0 || res.trashAt === null) {
                this.questoesDisponiveis.push(res);
                this.questoes.push(res);
              }
            });
          this.total = this.questoes.length;
          this.totalPaginas = response.data.paginas;
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          this.$root.$emit("Spinner::hide");

          if (error.response.status == 403) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">Oops! Você não tem permissão para executar esta ação.</span>',
              showConfirmButton: false,
              timer: 2500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.violations[0].message +
                "</span>",
              showConfirmButton: false,
              timer: 2500,
            });
          }
          this.$root.$emit("Spinner::hide");
        });
    },
    loadAssuntos(id) {
      this.$api.get("assuntos-disciplina/" + id).then((response) => {
        if (
          localStorage.getItem(constantes.STORAGE_UNIAVAN_ID_INSTITUICAO) == 0
        ) {
          response.data.forEach((res) => {
            if (res.instituicao == null) {
              this.assuntosDisponiveis.push(res);
              this.assuntos.push(res);
            }
          });
        } else {
          this.assuntosDisponiveis = response.data;
          this.assuntos = response.data;
        }
        this.$root.$emit("Spinner::hide");
      });
    },
    onChangeAno() {
      this.alunos = [];
      this.prova.alunos = [];
      this.turmaSelecionada = null;
      this.loadTurmas();
    },
    onChangeTurma() {
      this.alunos = [];
      this.prova.alunos = [];
      this.$api.get("alunos-turma/" + this.turmaSelecionada).then((response) => {
        console.log(response.data);
        this.alunos = response.data;
        this.prova.alunos = response.data;
        // this.prova.alunos = response.data;
        this.checkAllAlunos = true;
        // aqui
      });
    },
    loadTurmas() {
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.$root.$emit("Spinner::show");
      let turmasPorAno = [];
      this.$api.get("turma_ensinos").then((response) => {
        response.data.forEach((res) => {
          if (res.anoEnsino.id == this.prova.ano) {
            if (res.trashAt == null) {
              if (res.instituicao == null || res.instituicao.id == idInstituicao)
                turmasPorAno.push(res);
            }
          }
        });
        this.turmas = turmasPorAno;
        console.log(this.turmas);
        // this.turmas = response.data;
        this.$root.$emit("Spinner::hide");
      });
    },
    lookAlunos(aluno) {
      if (this.prova.alunos.includes(aluno)) {
        let i = this.prova.alunos.findIndex((x) => x.id == aluno.id);
        this.prova.alunos.splice(i, 1);
      } else {
        this.prova.alunos.push(aluno);
      }
      console.log(this.alunos);
    },
    highlightMatches(text) {
      if(!this.filter || !this.filter.toLowerCase()){
        return text;
      }
      const matchExists = text
        .toLowerCase()
        .includes(this.filterAlunos.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filterAlunos, "ig");
      return text.replace(
        re,
        (matchedText) =>
          `<strong style="color: #26A54E !important;">${matchedText}</strong>`
      );
    },
    listarAlunos() {
      this.showModalAlunos = true;
    },
    loadAlunos(idTurma) {
      this.$api.get("alunos-turma/" + idTurma).then((response) => {
        this.alunos = response.data;
      });
    },
    loadAlunosSelecionados(idProva) {
      this.$api.get("alunos-prova/" + idProva).then((response) => {
        this.prova.alunos = response.data;
      });
    },
    loadDisciplinas() {
      this.$root.$emit("Spinner::show");

      this.$api.get("disciplinas").then((response) => {
        response.data.forEach((res) => {
          if (res.trashAt == null) {
            this.disciplinas.push(res);
            this.disciplinasDisponiveis.push(res);
          }
        });
        this.$root.$emit("Spinner::hide");
      });
    },
    loadAnos() {
      this.$root.$emit("Spinner::show");
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      let anosInstituicao = [];
      this.$api.get("ano_ensinos").then((response) => {
        this.anosDisponiveis = response.data;
        if (idInstituicao == 0) {
          this.anos = response.data;
        } else {
          response.data.forEach((res) => {
            if (res.trashAt == null) anosInstituicao.push(res);
          });
          this.anos = anosInstituicao;
        }
        this.$root.$emit("Spinner::hide");
      });
    },
    loadProva() {
      this.$root.$emit("Spinner::show");

      this.$api.get("provas/" + this.$route.params.id).then((response) => {
        this.prova = response.data;
        this.prova.questoes = this.prova.provaQuestoes;
        this.prova.numeroQuestoes = this.prova.qtdQuestoes;
        this.disciplinaSelecionada = this.prova.disciplina.id;
        this.prova.ano = this.prova.turma.anoEnsino.id;
        this.turmaSelecionada = this.prova.turma.id;
        this.prova.peso = this.prova.notaMaxima;
        this.pesoQuestaoProva = this.prova.notaMaxima;
        this.prova.descricao = this.prova.observacoes;
        this.loadAlunos(this.prova.turma.id);
        this.loadAlunosSelecionados(this.prova.id);
        this.loadTurmas();
        this.loadQuestoes();
        this.$root.$emit("Spinner::hide");
        this.loadAssuntos(this.prova.disciplina.id);
      });
    },
    sincronizarDados() {
      delete this.prova.criadaPor;
      this.prova.ordem = this.prova.ordemQuestoes;
      this.prova.dataHora = new Date(this.prova.dataHoraInicio);
      this.prova.tempoExecucao = this.prova.duracao;
    },
    enviarRevisao() {
      if (this.prova.questoes.length <= 0) {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não poder enviar para revisão uma prova sem questões.</span>',
          showConfirmButton: false,
          timer: 4000,
        });
      } else {
        this.$api
          .post("/provas-fluxo-status/" + this.prova.id, {
            status: "EmRevisao",
          })
          .then((response) => {
            console.log(response);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Prova enviada para revisão com sucesso!",
              showConfirmButton: false,
              timer: 1500,
            });
            this.prova.status = "EmRevisao";
          })
          .catch((error) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">Oops, Ocorreu um erro!</span>',
              showConfirmButton: false,
              timer: 3500,
            }).then(() => {
              this.loadData();
              this.showModalCreate = false;
            });
          });
      }
    },
    preview() {
      this.provaPreview = this.prova;
      console.log(this.provaPreview);
      this.showPreviewModal = true;
    },
  },
};
</script>

<style>
.vm {
  max-width: 500px !important;
  width: 500px !important;
}

.activeMenu {
  border-bottom: 5px solid var(--proj-primary);
  padding-bottom: 10px !important;
}

.badge {
  background-color: white;
  text-align: center;
  color: black;
  font-size: 18px;
}
.div-fixa {
  position: fixed;
  left: 0px;
  top: 100px;
  overflow: hidden;
  padding: 6px;
}
.div-fixa {
  position: fixed;
  left: 0px;
  top: 100px;
  overflow: hidden;
  padding: 6px;
}

.bd-2 {
  font-size: 14px;
  padding-left: 0;
}
.div-fixa-menu {
  position: fixed;
  z-index: 999;
  width: 100%;
  margin-top: 70px;
}
.div-fixa-menu2 {
  position: fixed;
  z-index: 999;
  width: 100%;
}

.menu-fixo {
  position: fixed;
  z-index: 999;
  width: 100%;
}

.alunos {
  background-color: #f6f5f5;
  height: 45px;
  border-radius: 5px;
}
</style>
